<template>
    <div class="space_home">
      <router-view></router-view>
    </div>
  </template>
  
  <script>
    export default {};
  </script>
  
  <style lang="less" scoped>
    // @import "./less/teaching_actvity.less";
  </style>